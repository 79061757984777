import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Img from 'gatsby-image/withIEPolyfill'

import Media from 'react-bootstrap/Media'

import Layout from '../components/layout'
import Testimonial from '../components/testimonial'
import LookingForMore from '../components/looking-for-more'

export default class Educators extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const ella = get(this, 'props.data.ella')
    const brenda = get(this, 'props.data.brenda')
    const connor = get(this, 'props.data.connor')
    const educationImg = get(this, 'props.data.file.childImageSharp.fluid')
    const metaDescription = `${siteTitle} has everything you need to get started making math fun and easy! Try our free PreK, Kindergarten, and elementary resources!`
    return (
      <Layout pageTitle="Educators" description={metaDescription}>
        <h1>Educators</h1>
        <hr />
        <Testimonial node={ella} />
        <Testimonial node={brenda} />
        <Testimonial node={connor} />
        <hr />
        <LookingForMore titleText="Ready to get started?" />
        <hr />
        <Media className="m-2 p-3">
          <Img
            fluid={educationImg}
            alt="books on a bookshelf"
            style={{ width: '6rem', height: '6rem' }}
          />
          <Media.Body className="ml-3">
            <p className="lead">
              {siteTitle} was piloted in a range of settings including:
              preschool, elementary and remedial classrooms.
            </p>
            <p>
              {siteTitle} is a hands-on Number Sense and Fact Fluency program
              that develops these skills through intentional play. Children gain
              confidence in a subject that many find intimidating. With{' '}
              {siteTitle}, learning math becomes fun, easy and empowering.
            </p>
          </Media.Body>
        </Media>
      </Layout>
    )
  }
}

export const Query = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
    file(relativePath: { eq: "blocks-and-dice.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 100, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    connor: contentfulTestimonial(
      id: { eq: "498f3c39-5733-5572-a416-32f56f175595" }
    ) {
      author
      authorPosition
      testimonial {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
    }
    ella: contentfulTestimonial(
      id: { eq: "33fc4b1f-b52e-5705-8e2d-06d1f2b28557" }
    ) {
      author
      authorPosition
      testimonial {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
    }
    brenda: contentfulTestimonial(
      id: { eq: "e1f814ac-a7d8-573b-8aab-ffb6747d1dc1" }
    ) {
      author
      authorPosition
      testimonial {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
    }
  }
`
