import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import get from 'lodash/get'

import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'

const LookingForMore = ({ data, titleText = 'Looking for more?' }) => {
  const siteTitle = get(data, 'site.siteMetadata.title')
  const banner = get(data, 'banner.childImageSharp.fluid')
  return (
    <Card className="m-2">
      <Card.Img
        as={Img}
        fluid={banner}
        alt="banner"
        style={{ height: '1rem' }}
      />
      <Card.Body>
        <Card.Title as="h2">{titleText}</Card.Title>
        <Button
          as={Link}
          to="/shop/preschool-kit/"
          variant="outline-primary m-2"
        >
          {siteTitle} Preschool Program
        </Button>
        <Button as={Link} to="/resources/levels/" variant="outline-primary m-2">
          K-2 Levels of Instruction
        </Button>
      </Card.Body>
      <Card.Img
        as={Img}
        fluid={banner}
        alt="banner"
        style={{ height: '3rem' }}
      />
    </Card>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      {
        site {
          siteMetadata {
            title
          }
        }
        banner: file(relativePath: { eq: "banner.jpg" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <LookingForMore data={data} {...props} />}
  />
)
